import styles from '../../styles/modules/home/HomeFeatures.module.scss';

const HomeFeatures = () => {
  return (
    <section className={['block', styles.block].join(' ')}>
      <div className="fixed">
        <h3 className={styles.title}>Почему именно мы?</h3>
        <div className={styles.features}>
          <div className={styles.featuresItem}>
            <div className={styles.featuresItemText}>31 год на рынке недвижимости</div>
            <div className={styles.featuresItemBar}>
              <div className={styles.featuresItemImage}>
                <img src="/images/pattern.svg" alt="" />
              </div>
              <div className={styles.featuresItemNumber} style={{ right: 30 }}>
                1
              </div>
            </div>
          </div>
          <div className={styles.featuresItem}>
            <div className={styles.featuresItemText}>Индивидуальный подход к клиенту</div>
            <div className={styles.featuresItemBar}>
              <div className={styles.featuresItemImage}>
                <img src="/images/pattern.svg" alt="" />
              </div>
              <div className={styles.featuresItemNumber} style={{ right: 25 }}>
                2
              </div>
            </div>
          </div>
          <div className={styles.featuresItem}>
            <div className={styles.featuresItemText}>Гарантия безопасности сделки</div>
            <div className={styles.featuresItemBar}>
              <div className={styles.featuresItemImage}>
                <img src="/images/pattern.svg" alt="" />
              </div>
              <div className={styles.featuresItemNumber}>3</div>
            </div>
          </div>
          <div className={styles.featuresItem}>
            <div className={styles.featuresItemText}>Договорные правовые отношения</div>
            <div className={styles.featuresItemBar}>
              <div className={styles.featuresItemImage}>
                <img src="/images/pattern.svg" alt="" />
              </div>
              <div className={styles.featuresItemNumber}>4</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeFeatures;
